import useMenuList from "custom-hook/getMenuList";
import React, { Fragment, useState } from "react";
import Select from "react-select";

import { Modal } from "react-bootstrap";
import { useEffect } from "react";
import { useCallback } from "react";

const ModalHakAkses = (props) => {
  const [menuChecked, setMenu] = useState([])
  const { menus, post, getById } = useMenuList(props.setModal)

  const handleChecked = (id) => {
    const check = menuChecked.includes(id)
    if (!!check) {
      setMenu((e) => e.filter(item => item !== id))
    } else {
      setMenu((e) => [id, ...e])
    }
  }

  const handleSubmit = () => {
    post({
      peg_nip: props.propsModal.nip,
      menu: menuChecked
    })
  }

  const getMenuUserById = useCallback(() => {
    getById(props.propsModal.nip)
  }, [getById, props.propsModal.nip])

  useEffect(getMenuUserById, [])
  return (
    <Fragment>
      <Modal
        size="lg"
        show={props.modal}
        onHide={() => props.setModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header className="header-modal" closeButton>
          <Modal.Title
            className="title-modal"
            id="example-modal-sizes-title-lg"
          >
            Hak Akses
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row reset-margin-row">
            <div className="col-md-12 content-modal">
              <div className="container-fluid">
                <div className="text-row flex-start">
                  <span className="ml-2 mr-5">NIP: {props.propsModal.nip}</span>
                  <span className="mr-5">Nama Lengkap: {props.propsModal.nama}</span>
                  <span>Jabatan: {props.propsModal.jabatan}</span>
                </div>
                <div>
                  <div className="p-3">
                    <label htmlFor="">Status*</label>
                    <Select
                      defaultValue=""
                      options={[
                        { label: "Aktif", value: "aktif" },
                        { label: "Non Aktif", value: "nonAktif" }
                      ]}
                      onChange={(e) => { }}
                    />
                  </div>
                  <ul className="no-list display-table">

                    <li className="list-style">
                      <ul className="no-list p-3">
                        <p>Menu Akses</p>
                        {menus.map((item, i) => {
                          return (
                            <li key={i}>
                              <input type="checkbox" id={`${item.name}-${i}`} onChange={() => handleChecked(item._id)} />
                              <label htmlFor={`${item.name}-${i}`}>
                                <i className={`fa ${item.ico}`}></i>
                                {item.name}
                              </label>
                            </li>
                          )
                        })}
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row reset-margin-row ">
            <div className="col-md-12 text-right mb-3">
              <button className="btn btn-default mr-2">Batal</button>
              <button className="btn btn-primary mr-2" onClick={handleSubmit}>Kirim</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ModalHakAkses;
